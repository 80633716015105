<template>
  <div class="mb-2">
    <v-progress-linear
        v-if="loading"
        indeterminate
        color="orange"
        absolute
        top
    ></v-progress-linear>

    <v-card outlined color="transparent">
      <v-card-text class="pa-0">
        <v-card>
          <v-card-title>
            <v-row no-gutters justify="space-between" align="center" class="mx-4">
              <v-col cols="3">
                <v-btn text color="primary" @click="openReportDialog = true" >
                  {{$t('sideBar.transactions_seller')}}
                  <v-icon right>
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

<!--          <v-row>
            <time-picker @callTheFuction="addFilter" />
          </v-row>-->

          <v-card-text class="pb-1">
            <v-data-table
                :headers="transactionsHeaders"
                :items="transactions"
                :items-per-page="transactions.length"
                :height="getTableHeight"
                class="my-table"
                item-key="id"
                dense
                outlined
                hide-default-footer
            >
              <template v-slot:item.created_at="{ item }">
                {{ formatDateTime(item.created_at) }}
              </template>
              <template v-slot:[`body.append`]>
                <tr>
                  <td :colspan="12">
                    <v-progress-linear
                        v-if="isLoadingItems"
                        indeterminate
                        color="primary"
                    ></v-progress-linear>
                    <div
                        v-observer="{ nextPage: nextPage }"
                        class="observer"
                        v-if="!loadMoreDisabled && !isLoadingItems"
                    ></div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
      <transactions-details-dialog v-if="openReportDialog" @close="openReportDialog = false" :visible="openReportDialog"  :type="'seller'"/>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notifications from "@/mixins/notifications";
import DeleteConfirmDialog from "@/components/dialog/deleteConfirmDialog.vue";
import AddEditOrderDialog from "@/components/orders/addEditOrderDialog.vue";
import statisticService from "@/services/request/statistic/statisticService";
import TimePicker from "@/components/UI/timePicker.vue";
import dataTableMixin from "@/mixins/dataTableMixin";
import loader from "@/mixins/loader";
import transactionsService from "@/services/request/transactions/transactionsService";
import TransactionsDetailsDialog from "@/components/transactions/transactionsDetailsDialog.vue";

export default {
  components: {TransactionsDetailsDialog, TimePicker, AddEditOrderDialog, DeleteConfirmDialog },
  mixins: [notifications, dataTableMixin, loader],
  data: () => ({
    loading: false,
    transactions: [],
    offsetBottom: 235,
    filterProps: {},
    openReportDialog: false,
  }),
  computed: {
    ...mapGetters(["currentLanguage"]),
    transactionsHeaders() {
      return [
        {text: this.$t('headers.date'), value: 'created_at'},
        {text: this.$t('headers.name'), value: 'user_name'},
        {text: this.$t('headers.id_transactions'), value: 'id'},
        {text: this.$t('headers.id_order'), value: 'order_id'},
        {text: this.$t('headers.operations'), value: 'transaction_type'},
        {text: this.$t('headers.sum'), value: 'amount'},
        {text: this.$t('headers.balance_after_operation'), value: 'balance_after'},
        {text: this.$t('headers.comment'), value: 'comment'},
      ];
    },
  },
  mounted() {
    this.fetchTransactions(1);
  },
  methods: {
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${day}.${month}.${year}, ${hours}:${minutes}`;
    },
    addFilter(props) {
      this.filterProps = props;
      this.fetchTransactions(1);
    },
    async fetchTransactions(page) {
      this.isLoadingItems = true;
      try {
        const params = {};
        /*if(this.filterProps.date_created_begin && this.filterProps.date_created_end) {
          params.start_date = this.filterProps.date_created_begin;
          params.end_date = this.filterProps.date_created_end;
        } else if(this.filterProps.time_created) {
          params.start_date = this.filterProps.time_created;
          params.end_date = this.filterProps.time_created;
        }*/
        params.page = page;
        params.per_page = this.perPage;
        const response = await transactionsService.getTransactionsSeller(params);
        if (response && response.length > 0) {
          if (page > 1) {
            this.transactions = [...this.transactions, ...response];
          } else {
            this.transactions = response;
          }
        }
        this.loadMoreDisabled = response.length < this.perPage;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoadingItems = false;
      }
    },
    async nextPage() {
      this.currentPage += 1;
      this.isLoadingItems = true;
      await this.fetchTransactions(this.currentPage);
      this.isLoadingItems = false;
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <v-dialog
      v-model="visibility"
      max-width="800"
      content-class="cross__dialog"
      :transition="$dialogTransition"
      scrollable
  >
    <v-card>
      <v-fade-transition>
        <v-progress-linear
            v-if="loading"
            absolute
            top
            indeterminate
            color="primary"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loading">
          <v-btn fab small elevation="0" @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title>
        {{
          $t("statistic.report")
        }}
      </v-card-title>
      <v-card-text class="pb-1">
        <v-data-table
            :headers="transactionsHeaders"
            :items="transactions"
            :items-per-page="transactions.length"
            class="my-table"
            item-key="id"
            dense
            outlined
            hide-default-footer
        >
          <!--          <template v-slot:[`body.append`]>
                      <tr>
                        <td :colspan="12">
                          <v-progress-linear
                              v-if="isLoadingItems"
                              indeterminate
                              color="primary"
                          ></v-progress-linear>
                          <div
                              v-observer="{ nextPage: nextPage }"
                              class="observer"
                              v-if="!loadMoreDisabled && !isLoadingItems"
                          ></div>
                        </td>
                      </tr>
                    </template>-->
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import dialogMixin from "@/mixins/dialogMixin";
import notifications from "@/mixins/notifications";
import transactionsService from "@/services/request/transactions/transactionsService";
import loader from "@/mixins/loader";

export default {
  data: () => ({
    loading: false,
    transactions: [],
    isLoadingItems: false
  }),
  mixins: [dialogMixin, notifications, loader],
  components: {
    ConfirmLeftDialog,
  },
  props: {
    type: {
      required: true,
    },
  },
  async mounted() {
    await this.fetchTransactions();
  },
  methods: {
    async fetchTransactions() {
      try {

        const response = this.type === 'seller' ? await transactionsService.getTransactionsReportSeller() : await transactionsService.getTransactionsReportArbitrageur();

        this.transactions = response;

      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    transactionsHeaders() {
      const baseHeaders = [
        { text: this.$t('statistic.total_counted'), value: 'total_credited' },
        { text: this.$t('statistic.total_payments'), value: 'total_debited' },
        { text: this.$t('statistic.balance'), value: 'balance' },
      ];

      const sellerHeaders = [
        { text: this.$t('headers.name'), value: 'name' },
      ];

      return this.type === 'seller' ? [...sellerHeaders, ...baseHeaders] : baseHeaders;
    },
  },
};
</script>

<style></style>

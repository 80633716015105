<template>
  <TransactionsSellerComponent />
</template>


<script>

import TransactionsSellerComponent from "@/components/transactions/transactionsSellerComponent.vue";

export default {
  name: "TransactionsSellerView",
  components: {
    TransactionsSellerComponent
  },
};
</script>